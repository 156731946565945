import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosWithToken from "../common/axiosWithToken";
import { apiServer } from "../common/globals";
import { Context } from "../common/Store";
import styles from "../styles/main.module.scss";

const MySubjects = () => {
    const [subjectData, setSubjectData] = useState([]);
    const [mySubjectNames, setMySubjectNames] = useState([]);
    const [mySubjectParticipants, setMySubjectParticipants] = useState([]);
    const [showEditDeleteMenu, setShowEditDeleteMenu] = useState(false);
    const [showHelpMessage, setShowHelpMessage] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // fetch your subjects
        axiosWithToken.get(apiServer + "/subjects", { withCredentials: true }).then(res => {
            if (res.data.length === 0) {
                setShowHelpMessage(true);
                return;
            }
            setSubjectData(res.data);
        }).catch(() => {
            navigate("/");
        });
    }, []);

    // effect for loading data
    useEffect(() => {
        // reducer to set subject names
        setMySubjectNames(subjectData.reduce((r, current) => {
            r[current.tsid] = current.name;
            return r;
        }, Object.create(null)));
        // reducer to set subject participants
        setMySubjectParticipants(subjectData.reduce((r, current) => {
            r[current.tsid] = r[current.tsid] || [];
            r[current.tsid].push(current.username);
            return r;
        }, Object.create(null)));
        
    }, [subjectData]);


    function removeSubject(id, name) {
        if (window.confirm(`Are you sure you want to delete ${name}?`))
            axiosWithToken.delete(apiServer + "/subject/" + id, { withCredentials: true }).then(() => {
                setSubjectData(prevState => {
                    const newState = prevState.filter(obj => obj.tsid !== parseInt(id));
                    if (newState.length === 0) setShowHelpMessage(true);
                    return newState;
                });
            });
    
    }

    return <>
        <div className={styles.floating}>
            <div className={styles.header}>
                <div className="headerElement">
                    <h2>My subjects</h2>
                    <div className={styles.spacedRow}>
                        <button onClick={() => setShowEditDeleteMenu(!showEditDeleteMenu)}>
                            <img src="/icons/edit.svg" alt="Edit"/>
                        </button>
                        <button onClick={() => navigate("/newsubject")}>
                            <img src="/icons/plus.svg" alt="Add"/>
                            <img src="/icons/subject.svg" alt="Subject"/>
                        </button>
                    </div>
                </div>
                {(subjectData.length > 0) && Object.keys(mySubjectNames).map(id => {
                    return (
                        <SubjectElement
                            key={id}
                            id={id}
                            name={mySubjectNames[id]}
                            showEditDeleteMenu={showEditDeleteMenu}
                            participants={mySubjectParticipants[id]}
                            removeSubject={() => removeSubject(id, mySubjectNames[id])}
                        />
                    );
                })}
                {showHelpMessage && <div className={styles.helpMessage}>
                    <h3>Welcome to the TODO app!</h3>
                    <ul>
                        <li>To add a new subject press on the <img src="/icons/plus.svg" alt="Add"/><img src="/icons/subject.svg" alt="Subject"/> icon</li>
                        <li>To delete a subject press on the <img src="/icons/edit.svg" alt="Edit"/> icon</li>
                        {/* TODO <li>To edit or delete a subject longpress on it</li>*/}
                        <li>You can access a subject by pressing on it</li>
                    </ul>
                </div>}
            </div>
        </div>
    </>;
}

const SubjectElement = ({ id, name, showEditDeleteMenu, participants, removeSubject }) => {
    const [storeState, ] = useContext(Context);
    const [isShown, setIsShown] = useState(true);
    const navigate = useNavigate();

    const navigateToSubject = (id) => {
        axiosWithToken.post(apiServer + "/selectedsubject", { tsid: id }, { withCredentials: true });
        navigate("/subject/" + id);
    }
    return (<>
        {isShown && <div className={`${styles.mainElement} ${styles.paddedElement}`} key={id}>
            <div className={styles.paddedElement} style={{width: "100%", cursor: "pointer", padding: 0}} onClick={() => navigateToSubject(id)}>
                <div>{ name }</div>
                <div className={styles.participants}>
                    {participants && participants.map((participant, i) => {
                        return (
                            <div key={i}>
                                {(participant === storeState.username) ? "" : participant}
                            </div>
                        );
                    })}
                </div>
            </div>
            {showEditDeleteMenu && <div className={styles.spacedRow}>
                <button onClick={() => {
                    console.log("Edit me");

                    // TODO implement editing

                }}>
                    <img src="/icons/edit.svg" alt="Edit"/>
                </button>
                <button onClick={removeSubject}>
                    <img src="/icons/x.svg" alt="Delete"/>
                </button>
            </div>}
        </div>
    }</>);
}

export default MySubjects;