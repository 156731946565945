import { useEffect, useRef, useState } from "react";
import axiosWithToken from "../common/axiosWithToken";
import { apiServer } from "../common/globals";
import styles from "../styles/main.module.scss";
import stylesSingleElement from "../styles/singleelement.module.scss";

const styleOptions = [
    { fontWeight: "normal" },
    { fontWeight: "bold" },
    { fontStyle: "italic" },
    { color: "blue", fontWeight: "bold" },
    { color: "orangered", fontWeight: "bold" },
    { color: "#669999", fontWeight: "bold" },
];

const SingleElement = ({ teid, tsid, text, styleAtBeginning }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [hasInteractionHappened, setHasInteractionHappened] = useState(false);
    const [style, setStyle] = useState(styleAtBeginning || false);
    const [showStyleSelection, setShowStyleSelection] = useState(false);
    const [timeoutRefWhenChanged, setTimeoutRefWhenChanged] = useState(null);
    const longPressTimeoutRef = useRef(null);
    const debounceTimeoutRef = useRef(null);

    const longpressTimeoutTime = 500;
    const debounceTimeout = 475;

    useEffect(() => {
        if (hasInteractionHappened) {
            if (debounceTimeoutRef.current !== null)  // clear timeout if needed
                clearTimeout(debounceTimeoutRef.current);
            debounceTimeoutRef.current = setTimeout(() => {
                if (isChecked) {
                    axiosWithToken.delete(apiServer + "/element/" + teid, { withCredentials: true });
                } else {
                    axiosWithToken.post(apiServer + "/element", {
                        id: tsid,
                        text,
                        style
                    }, { withCredentials: true });
                }
                console.log("Poslal sem podatke :)", tsid, isChecked)
                debounceTimeoutRef.current = null; // clear reference
            }, debounceTimeout);
        }
    }, [isChecked]);

    useEffect(() => {
        if (hasInteractionHappened) {
            axiosWithToken.put(apiServer + "/element/" + teid, { style }, { withCredentials: true });
        }
    }, [style]);

    return (
        <div className={`${styles.paddedElement} ${styles.mainElement} ${(showStyleSelection) ? stylesSingleElement.editingStyle : ""}`}
            style={ styleOptions[style] }
            onPointerDown={() => {
                // unblock useEffects
                setHasInteractionHappened(true);

                if (!isChecked) {
                    // set timeout for longpress
                    longPressTimeoutRef.current = setTimeout(() => {
                        setTimeoutRefWhenChanged(longPressTimeoutRef.current);
                        setShowStyleSelection(!showStyleSelection);
                    }, longpressTimeoutTime);
                }
            }}
            onPointerOut={() => {
                clearInterval(longPressTimeoutRef.current)
            }}
            onPointerUp={() => {
                // normal click has happened
                if (!showStyleSelection && longPressTimeoutRef.current !== timeoutRefWhenChanged) {
                    setIsChecked(prevState => !prevState);
                    clearInterval(longPressTimeoutRef.current);
                }
            }}
        >
            {text}
            {isChecked && <div className={stylesSingleElement.checkedElement} />}
            {showStyleSelection && <div className={stylesSingleElement.styleSelection} onClick={() => {
                setShowStyleSelection(false);
            }}>
                <div>
                    <div onClick={() => setStyle(0)}>N</div>
                    <div onClick={() => setStyle(1)}>B</div>
                    <div onClick={() => setStyle(2)}>i</div>
                    <div onClick={() => setStyle(3)} style={{ backgroundColor: "blue" }} />
                    <div onClick={() => setStyle(4)} style={{ backgroundColor: "orangered" }} />
                    <div onClick={() => setStyle(5)} style={{ backgroundColor: "#669999" }} />
                </div>
            </div>}
        </div>
    );
}

export default SingleElement;