import { createContext, useReducer, useEffect } from "react";
import axiosWithToken from "./axiosWithToken";

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_LOGGED_IN":
            return {
                ...state,
                isLoggedIn: action.payload
            }
        case "SET_USERNAME":
            return {
                ...state,
                username: action.payload
            };
        case "SET_UID":
            return {
                ...state,
                uid: action.payload
            };
        case "SET_ROLE":
            return {
                ...state,
                role: action.payload
            };
        case "SET_EXPIRES_IN":
            return {
                ...state,
                expiresIn: action.payload
            };
        default:
            return state;
    }
}

const initialState = {
    isLoggedIn: false,
    username: null,
    role: null,
    expiresIn: null,
    uid: null,
}

const Store = ({ children }) => {
    const [storeState, storeDispatch] = useReducer(reducer, initialState);

    // call on page refresh
    useEffect(() => {
        // gets data from cookie if refresh happens
        // loop through all cookies
        document.cookie.split(";").forEach((cookie) => {
            const cookieParts = cookie.split("=");
            // get cookie name and removes spaces
            const name = cookieParts[0].replace(" ", "");
            // check if userData cookie exists
            if (name === "userData") {
                // check if data is actually there
                if (cookieParts[1].length !== 0) {
                    // decode data from cookie
                    const data = JSON.parse(decodeURIComponent(decodeURI(cookieParts[1])));
                    // set values to store
                    storeDispatch({ type: "SET_LOGGED_IN", payload: data.isLoggedIn });
                    storeDispatch({ type: "SET_USERNAME", payload: data.username });
                    storeDispatch({ type: "SET_UID", payload: data.uid });
                    storeDispatch({ type: "SET_ROLE", payload: data.role });
                    storeDispatch({ type: "SET_EXPIRES_IN", payload: data.expiresIn });

                    // sets axiosWithToken variables
                    axiosWithToken.setStoreDispatch(storeDispatch);
                    axiosWithToken.setExpiresIn(data.expiresIn);
                }
            }
        });
    }, []);

    return (
        <Context.Provider value={[storeState, storeDispatch]}>
            { children }
        </Context.Provider>
    )
}

export const Context = createContext(initialState);
export default Store;