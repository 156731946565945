import { Suspense } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Store from './common/Store';

import Login from "./components/Login";
import Main from './components/Main';
import MySubjects from './components/MySubjects';
import NewSubject from './components/NewSubject';
import PageLoading from './components/PageLoading';
import "./styles/app.scss";

function App() {
    return (
        <Store>
            <BrowserRouter>
            <Suspense fallback={<PageLoading />}>
                <Routes>
                    <Route path="/subject/:id" element={<Main />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/mysubjects" element={<MySubjects />} />
                    <Route path="/newsubject" element={<NewSubject />} />
                </Routes>
                </Suspense>
            </BrowserRouter>
        </Store>
    );
}

export default App;