import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiServer, authenticationServer } from '../common/globals';
import { Context } from '../common/Store';

import axios from 'axios';
import axiosWithToken from '../common/axiosWithToken';
import styles from "../styles/forms.module.scss";

const Login = () => {
    const navigate = useNavigate();
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessageIsDisplayed, setErrorMessageIsDisplayed] = useState(false);
    const [, storeDispatch] = useContext(Context);

    // middleware for clearing error message
    const clearError = (updateState, value) => {
        setErrorMessageIsDisplayed(false);
        updateState(value);
    }

    // Login click
    const handleLoginClick = () => {
        axios.post(
            authenticationServer + "/login", 
            JSON.stringify({ username: username, password: password }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then(res => {
            // if credentials are wrong
            if (res.status !== 200) {
                alert("Wrong username or password");
            // if credentials are ok
            } else {
                // dispatch values to store
                storeDispatch({ type: "SET_LOGGED_IN", payload: true });
                storeDispatch({ type: "SET_USERNAME", payload: res.data.username });
                storeDispatch({ type: "SET_UID", payload: res.data.uid });
                storeDispatch({ type: "SET_ROLE", payload: res.data.role });
                storeDispatch({ type: "SET_EXPIRES_IN", payload: res.data.expiresIn });

                // sets axiosWithToken variables
                axiosWithToken.setStoreDispatch(storeDispatch);
                axiosWithToken.setExpiresIn(res.data.expiresIn);

                // redirect
                axiosWithToken.get(apiServer + "/selectedsubject", { withCredentials: true }).then(res => {
                    if (res.data.length > 0)
                        navigate(`/subject/${res.data[0].tsid}`);
                    else
                        navigate("/mysubjects");
                });
                
            }
        }).catch(() => {
            setErrorMessageIsDisplayed(true);
        });
    }

    return (
        <div className={styles.form} onKeyPress={(event) => { if (event.key === "Enter") handleLoginClick(); }}>
            <div className="content">
                <h1 className="centered">TODO</h1>
                <form>
                    <div className="formElement">
                        <label htmlFor="usernameInput">Username:</label>
                        <input 
                            id="usernameInput" 
                            type="text" 
                            autoComplete="off" 
                            onChange={event => clearError(setUsername, event.target.value)}
                        />
                    </div>
                    <div className="formElement">
                        <label htmlFor="passwordInput">Password:</label>
                        <input 
                            id="passwordInput" 
                            type="password" 
                            autoComplete="off" 
                            onChange={event => clearError(setPassword, event.target.value)}
                        />
                    </div>
                </form>
                <div id="buttonContainer">
                    <button onClick={handleLoginClick}>Log in</button>
                    <button onClick={() => 
                        window.location.href=`https://vrecer.si/register.html?from=${window.location.href}`
                    }>Register</button>
                </div>
                <div className="errorMessage">
                    { (errorMessageIsDisplayed) ? "Wrong login information!" : "" }
                </div>
            </div>
        </div>
    );
}

export default Login;