import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosWithToken from "../common/axiosWithToken";
import { apiServer } from "../common/globals";
import styles from "../styles/main.module.scss";

const NewSubject = () => {
    const [newSubjectName, setNewSubjectName] = useState("");
    const navigate = useNavigate();

    const addSubject = () => {
        if (newSubjectName.length === 0)
            return;
        axiosWithToken.post(apiServer + "/subject", {
            name: newSubjectName
        }, { withCredentials: true }).then(() => navigate("/mysubjects"));
    }

    return <div className={styles.main}>
        <div className={styles.header}>
            <div className="headerElement">
                <h2>Add new subject</h2>
            </div>
        </div>
        <div className={styles.form}>
        <input
            placeholder="Enter a name for the subject"
            autoFocus
            type="text"
            value={newSubjectName}
            onChange={event => setNewSubjectName(event.target.value) }
        />
        <div className="buttons">
            <button onClick={() => navigate("/mysubjects")}>Cancel</button>
            <button onClick={addSubject}>Add</button>
        </div>
        </div>
</div>;
}

export default NewSubject;