import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosWithToken from "../common/axiosWithToken";
import { apiServer } from "../common/globals";
import styles from "../styles/main.module.scss";

import SingleElement from "./SingleElement";

const Main = () => {
    const [showAddPeople, setShowAddPeople] = useState(false);
    const [showOrderBy, setSetShowOrderBy] = useState(false);
    const [orderBy, setOrderBy] = useState("text");
    const [subjectName, setSubjectName] = useState("");
    const [todos, setTodos] = useState([]);
    const [newElementName, setNewElementName] = useState("");
    const [addUserName, setAddUserName] = useState("");
    const [showHelpMessage, setShowHelpMessage] = useState(false);
    
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        fetchSubjects();
    }, [params.id]);

    const fetchSubjects = () => {
        axiosWithToken.get(apiServer + "/subject/" + params.id, { withCredentials: true }).then(res => {
            setSubjectName(res.data[0].name);
            if (res.data[0].teid === null) {
                setTodos([]);
                setShowHelpMessage(true);
                return;
            } else {
                setTodos(res.data);
                setShowHelpMessage(false);
            }
            
        }).catch(() => {
            navigate("/");
        });
    }

    const addNewElementButtonPress = () => {
        if (newElementName.length === 0)
            return;
        axiosWithToken.post(apiServer + "/element", {
            id: params.id,
            text: newElementName,
            style: 0,
        }, { withCredentials: true }).then(() => fetchSubjects());
        setNewElementName("");
        document.querySelector("input").focus();
    }

    const addUserToSubjectPress = () => {
        if (addUserName.length === 0)
            return;
        axiosWithToken.post(apiServer + "/subject/" + addUserName, {
            id: params.id,
        }, { withCredentials: true }).then(() => {
            setShowAddPeople(false);
            setAddUserName("");
        });
    }

    function orderArrayBy(arr, att, reverse) {
        const switchOrder = (reverse) ? -1 : 1;
        return arr.sort((a, b) => {
            if (a[att] < b[att]) return -1 * switchOrder;
            if (a[att] > b[att]) return 1 * switchOrder;
            return 0;
        });
    }

    function changeOrderBy(type) {
        setOrderBy(type);
        setSetShowOrderBy(false);
    }

    return <>
        <div className={styles.main} onKeyPress={(event) => { if (event.key === "Enter") addNewElementButtonPress(); }}>
            <div className={styles.header}>
                <div className="headerElement">
                    <h2>{subjectName}</h2>
                    <div className={styles.spacedRow}>
                        <RefreshButton fetchSubjects={fetchSubjects} />
                        <button onClick={() => setSetShowOrderBy(prevState => !prevState)}>
                            <img src="/icons/orderby.svg" alt="Change order" />
                            {showOrderBy && <div onClick={event => event.stopPropagation()} className={styles.orderByContainer}>
                                <div className="triangle" />
                                <div className="options">
                                    <div>Order by:</div>
                                    <div className={(orderBy === "text") ? "selected" : ""} onClick={() => changeOrderBy("text")}>Alphabet</div>
                                    <div className={(orderBy === "style") ? "selected" : ""} onClick={() => changeOrderBy("style")}>Style</div>
                                    <div className={(orderBy === "date") ? "selected" : ""} onClick={() => changeOrderBy("date")}>Insert date</div>
                                </div>
                            </div>}
                        </button>
                        <button onClick={() => {
                            navigate("/mysubjects");
                        }}>
                            <img src="/icons/subject.svg" alt="My subjects"/>
                        </button>
                        <button onClick={() => {
                            setShowAddPeople(true);
                        }}>
                            <img src="/icons/plus.svg" alt="Add"/>
                            <img src="/icons/person.svg" alt="Person"/>
                        </button>
                    </div>
                </div>
                <div className="headerElement">
                    <input 
                        type="text"
                        value={newElementName}
                        onChange={event => setNewElementName(event.target.value) }
                    />
                    <button
                        className={styles.headerButton}
                        onClick={addNewElementButtonPress}
                    >
                        <img src="/icons/plus.svg" alt="Plus"/>
                    </button>
                </div>
            </div>
            
            <div>
                {todos && orderArrayBy(todos, orderBy, orderBy === "date").map(todo => {
                    return (
                        <SingleElement key={todo.teid}
                            teid={todo.teid}
                            tsid={todo.tsid}
                            text={todo.text}
                            styleAtBeginning={todo.style}
                        />
                    );
                })}
                {showHelpMessage && <div className={styles.helpMessage}>
                    <h3 style={{paddingTop: "2rem"}}>This is {subjectName}!</h3>
                    <ul>
                        <li>To add a todo enter some text in the textfield on top of the page and press the <img src="/icons/plus.svg" alt="Plus"/> button</li>
                        <li>To <s>check</s> or uncheck a todo press on it</li>
                        <li>You can also longpress todos to style them</li>
                        <li>If you want to refresh the page press <img src="/icons/refresh.svg" alt="Refresh"/>
                        , to change the order press <img src="/icons/orderby.svg" alt="Change order" /> and to add a person to the subject press <img src="/icons/plus.svg" alt="Add"/><img src="/icons/person.svg" alt="Person"/></li>
                        <li>To return to the main page press the <img src="/icons/subject.svg" alt="My subjects"/> icon</li>
                    </ul>
                </div>}
            </div>
        </div>
        {showAddPeople && <div className={styles.floating} style={{borderTopWidth: 100, borderColor: "#FFF8F0", borderStyle: "solid", zIndex: 15 }}>
            <div className={styles.header}>
                <div className="headerElement">
                    <h2>Add someone to {subjectName}</h2>
                </div>
            </div>
            <div className={styles.form}>
                <input
                    type="text"
                    autoFocus
                    value={addUserName}
                    onChange={event => setAddUserName(event.target.value) }
                    placeholder="Enter a username"
                />
                <div className="buttons">
                    <button onClick={() => setShowAddPeople(false)}>Cancel</button>
                    <button onClick={addUserToSubjectPress}>Add</button>
                </div>
            </div>
        </div>}
    </>;
}

const RefreshButton = ({ fetchSubjects }) => {
    const [animated, setAnimated] = useState(false);
    
    function onButtonPress() {
        setAnimated(true);
        fetchSubjects()
    }
    
    return (
        <button
            onClick={onButtonPress}
            onAnimationEnd={() => setAnimated(false)}
        >
            <img src="/icons/refresh.svg" alt="Refresh" className={(animated ? `${styles.refreshAnimation}` : "")} />
        </button>
    );
}


export default Main;