import axios from "axios"
import { authenticationServer } from "./globals";

//? axiosWithToken is used for sending request to backend, where we need to check, if
//? the accessToken expiration date is too old

export default class axiosWithToken {
    static expiresIn = null;
    static storeDispatch = null;

    //* function loads data from cookie
    static #setInitialValuesFromCookie = () => {
        // gets data from cookie if refresh happens
        // loop through all cookies
        document.cookie.split(";").forEach((cookie) => {
            const cookieParts = cookie.split("=");
            // get cookie name and removes spaces
            const name = cookieParts[0].replace(" ", "");
            // check if userData cookie exists
            if (name === "userData") {
                // check if data is actually there
                if (cookieParts[1].length !== 0) {
                    // decode data from cookie
                    const data = JSON.parse(decodeURIComponent(decodeURI(cookieParts[1])));
                    
                    // sets axiosWithToken variables
                    //this.setStoreDispatch(storeDispatch);
                    this.setExpiresIn(data.expiresIn);
                }
            }
        });
    }

    static setExpiresIn = (expiresIn) => {
        this.expiresIn = expiresIn;
    }

    static setStoreDispatch = (storeDispatch) => {
        this.storeDispatch = storeDispatch;
    }

    //* function checks if expiresIn is expired
    static #checkExpireTime = async () => {
        // if accessToken is expired (with margin)
        if (this.expiresIn < Date.now() + 5000) {
            // request new accessToken
            await axios.post(authenticationServer + "/refreshToken", {}, { withCredentials: true })
                .then(res => {
                    // dispatch new expiresIn time to store
                    this.storeDispatch({ type: "SET_EXPIRES_IN", payload: res.data.expiresIn });
                    this.expiresIn = res.data.expiresIn;
                });
        }
    }

    //* to call GET method on url
    static get = async (url, config = { withCredentials: true }) => {
        // if variables are not set function doesn't continue
        if (this.expiresIn === null || this.storeDispatch === null)
            this.#setInitialValuesFromCookie();
        
        // check if time is expired
        await this.#checkExpireTime();

        // return response from url
        return await axios.get(url, config)
            .then(res => {
                return res;
            });
    }

    //* to call POST method on url
    static post = async (url, data = {}, config = { withCredentials: true }) => {
        // if variables are not set function doesn't continue
        if (this.expiresIn === null || this.storeDispatch === null)
            this.#setInitialValuesFromCookie();
        
        // check if time is expired
        this.#checkExpireTime();

        // return response from url
        return await axios.post(url, data, config)
            .then(res => {
                return res;
            });
    }

    //* to call PUT method on url
    static put = async (url, data = {}, config = { withCredentials: true }) => {
        // if variables are not set function doesn't continue
        if (this.expiresIn === null || this.storeDispatch === null)
            this.#setInitialValuesFromCookie();
        
        // check if time is expired
        this.#checkExpireTime();

        // return response from url
        return await axios.put(url, data, config)
            .then(res => {
                return res;
            });
    }

    //* to call DELETE method on url
    static delete = async (url, config = { withCredentials: true }) => {
        // if variables are not set function doesn't continue
        if (this.expiresIn === null || this.storeDispatch === null)
            this.#setInitialValuesFromCookie();
        
        // check if time is expired
        this.#checkExpireTime();

        // return response from url
        return await axios.delete(url, config)
            .then(res => {
                return res;
            });
    }
}