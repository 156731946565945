import styles from '../styles/pageLoading.module.scss';

const PageLoading = () => {
    return (
        <div className={ styles.pageLoading }>
            <div className="content" />
        </div>
    );
}

export default PageLoading;